/**
 * Copyright 2021 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import {ILogger} from '../../logger/LoggerInterface';
import LoggerFactory from '../../logger/LoggerFactory';
import {LiveStreaming} from './LiveStreaming';
import {LiveStreamingOptions, LiveStreamingStatistics} from './StreamTypes';
import {NetworkStates} from './NetworkStates';

const bandwidthToStartAt = 3000000; // Corresponds to 720p in most setups
const minTimeBeforeNextReload = 15000;

type EventListenerForWebPlayer = (evt: WebPlayerEvent) => void;
type WebPlayerEvent = {
  code: number;
  severity: number;
  error: string;
}

export interface IWebPlayer {
  start: (url: string) => void;
  addEventListener(name: string, listener: EventListenerForWebPlayer): void;
  dispose(): void;
  errors: {
    severity: {
      RECOVERABLE: number;
    };
  };
  getStats(): LiveStreamingStatistics;
}

export default class WebPlayer {
  private readonly _logger: ILogger = LoggerFactory.getLogger('WebPlayer');
  private readonly _videoElement: HTMLVideoElement;
  private readonly _kind: string;
  private readonly _streamId: string;
  private readonly _manifestURI: string;
  private readonly _options: LiveStreamingOptions;
  private _player: IWebPlayer;
  private _lastReloadTime: number;

  constructor(videoElement: HTMLVideoElement, kind: string, streamId: string, manifestURI: string, options: LiveStreamingOptions) {
    this._videoElement = videoElement;
    this._kind = kind;
    this._streamId = streamId;
    this._manifestURI = encodeURI(manifestURI).replace(/[#]/g, '%23');
    this._options = options;
  }

  start(): void {
    if (LiveStreaming.phenixWebPlayer) {
      const playerOptions = {
        ...this._options,
        bandwidthToStartAt
      };

      this._player = new LiveStreaming.phenixWebPlayer.WebPlayer(this._logger, this._videoElement, playerOptions);
      this._player.start(this._manifestURI);

      this._player.addEventListener('error', errorObject => {
        if (this.canReload() && errorObject && (errorObject.code === 3 || errorObject.severity === LiveStreaming.phenixWebPlayer.errors.severity.RECOVERABLE)) {
          this._logger.warn('Reloading unhealthy stream after error event [%j]', errorObject);
          this.reloadIfAble();
        }

        this._logger.error('Cannot reload with error [%j]', errorObject);
      });

      return;
    }
  }

  isSupported(): boolean {
    return LiveStreaming.phenixWebPlayer && LiveStreaming.phenixWebPlayer.isSupported;
  }

  getStats(): LiveStreamingStatistics {
    if (!this._player) {
      return {
        width: 0,
        height: 0,
        currentTime: 0.0,
        lag: 0.0,
        networkState: NetworkStates.NetworkNoSource,
        dataBuffered: 0.0
      };
    }

    const statistics = this._player.getStats();
    const currentTime = statistics.currentTime || this._videoElement['currentTime'];
    const trueCurrentTime = (Date.now() - this._options.originStartTime) / 1000;

    if (this._videoElement.buffered && this._videoElement.buffered.length) {
      statistics.dataBuffered = this._videoElement.buffered.end(0);
    }

    if (statistics.isNative && statistics.deliveryType === 'Hls') {
      statistics.currentTime = currentTime - statistics.lag;
    }

    if (!statistics.currentTime) {
      statistics.currentTime = currentTime;
    }

    if (!statistics.isNative) {
      statistics.lag = Math.max(0.0, trueCurrentTime - currentTime);
    }

    if (statistics.estimatedBandwidth > 0) {
      statistics.networkState = NetworkStates.NetworkLoading;
    } else if (statistics.playTime > 0) {
      statistics.networkState = NetworkStates.NetworkIdle;
    } else if (statistics.video) {
      statistics.networkState = NetworkStates.NetworkEmpty;
    } else {
      statistics.networkState = NetworkStates.NetworkNoSource;
    }

    return statistics;
  }

  dispose(): void {
    if (this._player) {
      this._player.dispose();
    }

    this._player = null;
  }

  private canReload(): boolean {
    const hasElapsedMinTimeSinceLastReload = !this._lastReloadTime || Date.now() - this._lastReloadTime > minTimeBeforeNextReload;

    return this._videoElement && this._player && this._videoElement.buffered.length !== 0 && hasElapsedMinTimeSinceLastReload;
  }

  private reloadIfAble(): void {
    if (!this.canReload()) {
      return;
    }

    this._logger.warn('[%s] Reloading unhealthy stream that was active for at least [%s] seconds', this._streamId, minTimeBeforeNextReload / 1000);

    this._lastReloadTime = Date.now();

    this.reload();
  }

  private reload(): void {
    this._player.dispose();

    this._player = null;

    this.start();
  }
}