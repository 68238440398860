/**
 * Copyright 2021 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import IPeerConnection from '../rtc/IPeerConnection';
import Subject from '../rx/Subject';
import FeatureEnablement from './FeatureEnablement';
import SdpParser from './SdpParser';
import IDisposable from '../lang/IDisposable';

export interface IRtcMonitorStatistic {
  [kind: string]: IRtcStatistic;
}

export interface ILegacyRTCStatsReport {
  result: () => ILegacyRTCStatsReportItem[];
}

export interface ILegacyRTCStatsReportItem extends RTCStatsReport {
  type: string;
  names: () => string[];
  stat: (name) => number;
}

export interface IRtcStatistic {
  ssrc?: string;
  mediaType?: string;
  timestamp: number;
  bytesReceived?: number;
  framesDecoded?: number;
  packetsLost?: number;
  packetsReceived?: number;
  codec?: string;
  fps?: number;
  roundTripTime?: number;
}

const updateStatisticTimeOut = 1000;

export default class RtcConnectionMonitor implements IDisposable {
  private readonly _estimatedRoundTripTime: number;
  private readonly _estimatedVideoCodec: string;
  private readonly _estimatedAudioCodec: string;
  private readonly _rtcStatistic: Subject<IRtcMonitorStatistic> = new Subject<IRtcMonitorStatistic>({});
  private _peerConnection: IPeerConnection;
  private _isMonitorRunning = true;
  private _updateTimeOut: number;
  private _tracksToMonitor: string[];

  constructor(peerConnection: IPeerConnection, mediaStream: MediaStream, estimatedRoundTripTime: number) {
    this._peerConnection = peerConnection;
    this._estimatedRoundTripTime = estimatedRoundTripTime;
    this._tracksToMonitor = mediaStream.getTracks().map(track => track.kind) || [];

    if (this._peerConnection) {
      if (!FeatureEnablement.getCurrentOfferDisabled) {
        const parsedSDP = new SdpParser(this._peerConnection.currentRemoteDescription.sdp);

        this._estimatedAudioCodec = parsedSDP.audioCodec;
        this._estimatedVideoCodec = parsedSDP.videoCodec;
      }

      this.updateStatistic();
    }
  }

  get rtcStatistic(): Subject<IRtcMonitorStatistic> {
    return this._rtcStatistic;
  }

  dispose(): void {
    this._isMonitorRunning = false;
    this._peerConnection = null;

    if (this._updateTimeOut) {
      clearTimeout(this._updateTimeOut);
      this._updateTimeOut = null;
    }
  }

  private async updateStatistic(): Promise<void> {
    if (FeatureEnablement.getStatsPromiseBasedDisabled) {
      const ignored = await this.updateStatisticLegacy();

      return;
    }

    // Safari 11 doesnt have kind or mediaType so we need to take it from the inbound-rtp object id
    const getKindForSafari11 = (item): string => item.id.includes('Audio') ? 'audio' : item.id.includes('Video') ? 'video' : '';
    const ignored = await this._peerConnection.getStats(null)
      .then(stats => {
        const rtcStats: IRtcMonitorStatistic = {};

        if (stats) {
          let roundTripTime = this._estimatedRoundTripTime;

          stats.forEach(i => {
            if (i.type === 'candidate-pair' && i.currentRoundTripTime) {
              roundTripTime = i.currentRoundTripTime * 1000;
            }

            if (i.type === 'inbound-rtp') {
              const kind = i.kind || i.mediaType || getKindForSafari11(i);
              let codec = '';

              stats.forEach(item => {
                if (item.id === i.codecId) {
                  codec = item.mimeType;
                }
              });

              if (kind && this._tracksToMonitor.includes(kind)) {
                rtcStats[kind] = {
                  ssrc: i.ssrc,
                  mediaType: kind,
                  timestamp: i.timestamp,
                  bytesReceived: i.bytesReceived,
                  packetsLost: i.packetsLost,
                  packetsReceived: i.packetsReceived,
                  codec: codec || this.getCodecByType(kind),
                  roundTripTime
                };

                if (kind === 'video' && this._rtcStatistic.value && this._rtcStatistic.value.video) {
                  rtcStats[kind].framesDecoded = i.framesDecoded;
                  rtcStats[kind]['fps'] = (i.framesDecoded - this._rtcStatistic.value.video.framesDecoded) /
                    (rtcStats[kind].timestamp - this._rtcStatistic.value.video.timestamp) *
                    1000 || 0;
                }
              }
            }
          });
        }

        this._rtcStatistic.value = rtcStats;
      }).then(() => {
        if (this._isMonitorRunning) {
          this._updateTimeOut = window.setTimeout(() => this.updateStatistic(), updateStatisticTimeOut);
        }
      });
  }

  private async updateStatisticLegacy(): Promise<void> {
    const ignored = await this._peerConnection.getStatsLegacy().then(stats => {
      const rtcStats: IRtcMonitorStatistic = {};
      let roundTripTime = this._estimatedRoundTripTime;

      stats.result().forEach(report => {
        report.names().forEach(name => {
          if (name === 'googRTT') {
            roundTripTime = report.stat(name);
          }
        });

        if (report.type === 'ssrc') {
          const mediaType = report.stat('mediaType').toString();

          rtcStats[mediaType] = {timestamp: Date.now()};
          rtcStats[mediaType]['ssrc'] = report.stat('ssrc').toString();
          rtcStats[mediaType]['mediaType'] = mediaType;
          rtcStats[mediaType]['bytesReceived'] = report.stat('bytesReceived');
          rtcStats[mediaType]['packetsLost'] = report.stat('packetsLost');
          rtcStats[mediaType]['packetsReceived'] = report.stat('packetsReceived');
          rtcStats[mediaType]['codec'] = report.stat('googCodecName').toString() || this.getCodecByType(mediaType);
          rtcStats[mediaType]['roundTripTime'] = roundTripTime;

          if (mediaType === 'video' && this._rtcStatistic.value && this._rtcStatistic.value.video) {
            rtcStats[mediaType]['framesDecoded'] = report.stat('framesDecoded');
            rtcStats[mediaType]['fps'] = (rtcStats[mediaType].framesDecoded - this._rtcStatistic.value.video.framesDecoded) /
              (rtcStats[mediaType].timestamp - this._rtcStatistic.value.video.timestamp) *
            1000 || 0;
          }
        }
      });
      this._rtcStatistic.value = rtcStats;
    }).then(() => {
      if (this._isMonitorRunning) {
        this._updateTimeOut = window.setTimeout(() => this.updateStatistic(), updateStatisticTimeOut);
      }
    });
  }

  private getCodecByType(type: string): string {
    switch (type) {
      case 'audio':
        return this._estimatedAudioCodec;
      case 'video':
        return this._estimatedVideoCodec;
      default:
        return 'unknown';
    }
  }
}