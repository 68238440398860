/**
 * Copyright 2021 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import Subject from '../../rx/Subject';
import IPeerConnection from '../../rtc/IPeerConnection';
import ChannelState from './ChannelState';
import Dimension from '../../video/Dimension';
import EndPoint, {IStream, SubscribeStatus} from '../discovery/EndPoint';
import VideoTelemetry from '../../video/VideoTelemetry';
import SessionTelemetry from '../../video/SessionTelemetry';
import {IRtcMonitorStatistic, IRtcStatistic} from '../../dom/RtcConnectionMonitor';
import SDK from '../SDK';
import assertUnreachable from '../../lang/assertUnreachable';
import DisposableList from '../../lang/DisposableList';
import {EdgeToken} from '../edgeAuth/EdgeToken';
import {BitsPerSecond, Millisecond} from '../../units/Units';

export default class ChannelContext {
  channelInitialization: Date;
  disposables: DisposableList;
  channelDisposables: DisposableList;
  rendererDisposables: DisposableList;
  videoElement: Subject<HTMLVideoElement>;
  token: Subject<EdgeToken>;
  peerConnection: Subject<IPeerConnection>;
  mediaStream: Subject<MediaStream>;
  state: Subject<ChannelState>;
  autoMuted: Subject<boolean>;
  autoPaused: Subject<boolean>;
  tokenExpiring: Subject<boolean>;
  authorized: Subject<boolean>;
  online: Subject<boolean>;
  loading: Subject<boolean>;
  playing: Subject<boolean>;
  standby: Subject<boolean>;
  stopped: Subject<boolean>;
  targetLag: Subject<Millisecond>;
  lag: Subject<Millisecond>;
  bitrateLimit: Subject<BitsPerSecond>;
  resolution: Subject<Dimension>;
  failureCount: Subject<number>;
  endPoint: Subject<EndPoint>;
  stream: Subject<IStream>;
  rtcStatistics: Subject<IRtcMonitorStatistic>;

  peerConnectionReconnectAttempts: number;
  isDisposed: boolean;
  videoTelemetry: VideoTelemetry;
  sessionTelemetry: SessionTelemetry;
  rtcAudioStatistic: IRtcStatistic;
  rtcVideoStatistic: IRtcStatistic;
  clearFailureCountTimeout: number;

  constructor(token: string, targetLag: number) {
    this.isDisposed = false;
    this.disposables = new DisposableList();
    this.channelDisposables = new DisposableList();
    this.rendererDisposables = new DisposableList();
    this.channelInitialization = new Date();
    this.videoElement = new Subject<HTMLVideoElement>(null);
    this.token = new Subject<EdgeToken>(token);
    this.peerConnection = new Subject<IPeerConnection>(null);
    this.mediaStream = new Subject<MediaStream>(null);
    this.state = new Subject<ChannelState>(ChannelState.Starting);
    this.autoMuted = new Subject<boolean>(false);
    this.autoPaused = new Subject<boolean>(false);
    this.tokenExpiring = new Subject<boolean>(false);
    this.authorized = new Subject<boolean>(true);
    this.online = new Subject<boolean>(true);
    this.loading = new Subject<boolean>(false);
    this.playing = new Subject<boolean>(false);
    this.standby = new Subject<boolean>(false);
    this.stopped = new Subject<boolean>(false);
    this.targetLag = new Subject<Millisecond>(targetLag);
    this.lag = new Subject<Millisecond>(0);
    this.bitrateLimit = new Subject<BitsPerSecond>(0);
    this.resolution = new Subject<Dimension>(Dimension.empty);
    this.failureCount = new Subject<number>(0);
    this.endPoint = new Subject<EndPoint>(null);
    this.stream = new Subject<IStream>(null);
    this.rtcStatistics = new Subject<IRtcMonitorStatistic>(null);
    this.peerConnectionReconnectAttempts = 0;
  }

  get streamId(): string {
    const stream = this.stream.value;

    if (!stream) {
      return '-';
    }

    return stream.streamId;
  }

  applyStatus(status: SubscribeStatus): void {
    switch (status) {
      case 'ok':
        break;
      case 'unauthorized':
      case 'geo-restricted':
      case 'geo-blocked':
        this.authorized.value = false;

      // eslint-disable-next-line no-fallthrough
      case 'no-stream':
      case 'not-found':
        this.failureCount.value = 0;
        this.playing.value = false;
        this.standby.value = true;
        this.stopped.value = false;
        this.stream.value = null;

        return;
      default:
        this.failureCount.value++;
        this.playing.value = false;
        this.standby.value = true;
        this.stopped.value = false;
        this.stream.value = null;
        this.state.value = ChannelState.Error;

        return;
    }
  }

  applySessionAndStreamPropertiesToVideoElement(): void {
    if (this.videoElement.value && this.videoElement.value.dataset) {
      this.videoElement.value.dataset.sessionId = SDK.clientSessionId;
      this.videoElement.value.dataset.streamId = this.streamId;
    }
  }

  mapSubscribeStatusToChannelStatus(status: SubscribeStatus): ChannelState {
    switch (status) {
      case 'ok':
        return ChannelState.Starting;
      case 'no-stream':
      case 'not-found':
        return ChannelState.StandBy;
      case 'geo-restricted':
        return ChannelState.GeoRestricted;
      case 'geo-blocked':
        return ChannelState.GeoBlocked;
      case 'unauthorized':
        return ChannelState.Unauthorized;
      case 'capacity':
      case 'rate-limited':
      case 'timeout':
        return ChannelState.Recovering;
      case 'failed':
        return ChannelState.Error;
      default:
        assertUnreachable(status);
    }
  }
}