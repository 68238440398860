/**
 * Copyright 2021 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import {BitrateState, BitrateStateType} from '../api/SetTemporaryMaximalBitrate';
import assertUnreachable from '../../lang/assertUnreachable';

export default class BitrateStateMapping {
  static convertBitrateStateToBitrateStateType(bitrateState: BitrateState): BitrateStateType {
    switch (bitrateState) {
      case BitrateState.Keep:
        return 'Keep';
      case BitrateState.Reset:
        return 'Reset';
      default:
        assertUnreachable(bitrateState);
    }
  }
}