/**
 * Copyright 2021 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */

import Channel from './Channel';
import SDK from '../SDK';
import {EdgeToken} from '../edgeAuth/EdgeToken';
import {Millisecond} from '../../units/Units';

type CreateChannelOptions = {
  videoElement: HTMLVideoElement;
  token: EdgeToken;
  targetLag?: Millisecond;
}

export default class Channels {
  static createChannel(options: CreateChannelOptions): Channel {
    if (!SDK.initialized.value) {
      throw new Error('SDK is not loaded.');
    }

    return new Channel(options.videoElement, options.token, options.targetLag);
  }

  private constructor() {
    throw new Error('Channels is a static class that may not be instantiated');
  }
}