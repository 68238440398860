/**
 * Copyright 2021 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import {LiveStreaming} from './LiveStreaming';
import {LiveStreamingOptions, LiveStreamingStatistics} from './StreamTypes';
import {IHlsPlayerType} from './IHlsPlayer';
import {NetworkStates} from './NetworkStates';

export default class HlsPlayer {
  private readonly _videoElement: HTMLVideoElement;
  private readonly _kind: string;
  private readonly _streamId: string;
  private readonly _manifestURI: string;
  private readonly _options: LiveStreamingOptions;
  private _player: IHlsPlayerType;

  constructor(videoElement: HTMLVideoElement, kind: string, streamId: string, manifestURI: string, options: LiveStreamingOptions) {
    this._videoElement = videoElement;
    this._kind = kind;
    this._streamId = streamId;
    this._manifestURI = encodeURI(manifestURI).replace(/[#]/g, '%23');
    this._options = options;
  }

  start(): void {
    if (LiveStreaming.hlsPlayer) {
      if (LiveStreaming.hlsPlayer.isSupported()) {
        this._player = new LiveStreaming.hlsPlayer({
          enableWorker: true,
          maxBufferLength: 1,
          liveBackBufferLength: 0,
          liveSyncDuration: 7,
          liveDurationInfinity: true,
          liveMaxLatencyDuration: Infinity,
          highBufferWatchdogPeriod: 1
        });
        this._player.loadSource(this._manifestURI);
        this._player.attachMedia(this._videoElement);
      } else if (this._videoElement.canPlayType('application/vnd.apple.mpegurl')) {
        this._videoElement.src = this._manifestURI;
      }
    }
  }

  isSupported(): boolean {
    return LiveStreaming.hlsPlayer && LiveStreaming.hlsPlayer.isSupported();
  }

  getStats(): LiveStreamingStatistics {
    if (!this._player) {
      return {
        width: 0,
        height: 0,
        currentTime: 0.0,
        lag: 0.0,
        networkState: NetworkStates.NetworkNoSource
      };
    }

    const statistics = {
      width: this._player.media.offsetWidth,
      height: this._player.media.offsetHeight,
      currentTime: this._player.media.currentTime,
      lag: 0.0,
      networkState: NetworkStates.NetworkIdle,
      dataBuffered: 0.0
    };

    if (this._videoElement.buffered && this._videoElement.buffered.length) {
      statistics.dataBuffered = this._videoElement.buffered.end(0);
    }

    if (this._player.streamController.state === 'IDLE') {
      statistics.networkState = NetworkStates.NetworkIdle;
    } else if (this._player.streamController.state === 'ERROR' || this._player.streamController.state === 'ENDED') {
      statistics.networkState = NetworkStates.NetworkNoSource;
    } else if (this._player.streamController.state.includes('LOADING') || this._player.streamController.state.includes('WAITING')) {
      statistics.networkState = NetworkStates.NetworkLoading;
    } else {
      statistics.networkState = NetworkStates.NetworkEmpty;
    }

    const currentTime = this._videoElement['currentTime'] || 0;
    const trueCurrentTime = (Date.now() - this._options.originStartTime) / 1000;

    statistics.lag = Math.max(0.0, (currentTime + (this._options.lag / 1000)) - trueCurrentTime);

    return statistics;
  }

  dispose(): void {
    if (this._player && this._player.destroy) {
      this._player.destroy();

      this._player = null;
    }
  }
}