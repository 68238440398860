/**
 * Copyright 2021 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import BrowserDetector from './BrowserDetector';

export default class FeatureEnablement {
  static get clientOfferDisabled(): boolean {
    // Client offer workflow does not work correctly in the following browsers
    return BrowserDetector.isChrome74 ||
      BrowserDetector.isChrome75 ||
      BrowserDetector.isChrome76 ||
      BrowserDetector.isChrome77;
  }

  static get addTranceiverDisabled(): boolean {
    // Chrome 69, 70, 71, 72 exposes `addTranceiver` but it does not work correctly
    return BrowserDetector.isChrome69 ||
      BrowserDetector.isChrome70 ||
      BrowserDetector.isChrome71 ||
      BrowserDetector.isChrome72;
  }

  static get getStatsPromiseBasedDisabled(): boolean {
    return BrowserDetector.browserName === 'Chrome' && BrowserDetector.browserMajorVersion <= 66;
  }

  static get getCurrentOfferDisabled(): boolean {
    return BrowserDetector.browserName === 'Chrome' && BrowserDetector.browserMajorVersion <= 69 ||
      BrowserDetector.browserName === 'Firefox' && BrowserDetector.browserMajorVersion <= 56;
  }

  static get onTrackDisabled(): boolean {
    return BrowserDetector.browserName === 'Chrome' && BrowserDetector.browserMajorVersion <= 63 ||
      BrowserDetector.browserName === 'Firefox' && BrowserDetector.browserMajorVersion <= 52;
  }

  static get webkitRTCPeerConnectionEnabled(): boolean {
    return BrowserDetector.browserName === 'Chrome' && BrowserDetector.browserMajorVersion <= 55;
  }

  static get promiseBasedPCMethodsDisabled(): boolean {
    return BrowserDetector.browserName === 'Firefox' && BrowserDetector.browserMajorVersion <= 52;
  }

  static get shouldUseNativeHls(): boolean {
    return BrowserDetector.browserName === 'Safari' ||
      BrowserDetector.browserName === 'SamsungBrowser';
  }
  static get isRTMPEnabled(): boolean {
    return (BrowserDetector.browserName === 'Firefox' && BrowserDetector.browserMajorVersion < 84) ||
      (BrowserDetector.browserName === 'Chrome' && BrowserDetector.browserMajorVersion < 70) ||
      BrowserDetector.browserName === 'Edge' ||
      BrowserDetector.browserName === 'IE';
  }
}