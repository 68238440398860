/**
 * Copyright 2021 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
enum ChannelState {
  Offline = 1,
  Starting,
  Paused,
  Playing,
  Recovering,
  Reconnecting,
  StandBy,
  Stopped,
  Unauthorized,
  GeoRestricted,
  GeoBlocked,
  Error,
  UnsupportedFeature
}

export default ChannelState;