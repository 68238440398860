/**
 * Copyright 2021 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import {ILogger} from '../../logger/LoggerInterface';
import LoggerFactory from '../../logger/LoggerFactory';
import {LiveStreaming} from './LiveStreaming';
import {LiveStreamingOptions, LiveStreamingStatistics} from './StreamTypes';
import {NetworkStates} from './NetworkStates';

type ShakaOptions = {
  abr: {
    defaultBandwidthEstimate: number;
  };
  manifest: {
    retryParameters: {
      timeout: number;
    };
  };
  streaming: {
    rebufferingGoal: number;
    bufferingGoal: number;
    bufferBehind: number;
    retryParameters: {
      timeout: number;
      maxAttempts: number;
      backoffFactor: number;
    };
  };
}

export interface IShaka {
  configure: (o: ShakaOptions) => void;
  load: (uri: string) => Promise<void>;
  getStats: () => LiveStreamingStatistics;
  destroy: () => void;
  unload: () => void;
}

export default class ShakaPlayer {
  private readonly _logger: ILogger = LoggerFactory.getLogger('ShakaPlayer');
  private readonly _videoElement: HTMLVideoElement;
  private readonly _kind: string;
  private readonly _streamId: string;
  private readonly _manifestURI: string;
  private readonly _options: LiveStreamingOptions;
  private _player: IShaka;

  constructor(videoElement: HTMLVideoElement, kind: string, streamId: string, manifestURI: string, options: LiveStreamingOptions) {
    this._videoElement = videoElement;
    this._kind = kind;
    this._streamId = streamId;
    this._manifestURI = encodeURI(manifestURI).replace(/[#]/g, '%23');
    this._options = options;
  }

  start(): void {
    if (LiveStreaming.shaka) {
      const playerOptions: ShakaOptions = {
        abr: {defaultBandwidthEstimate: 2000000},
        manifest: {retryParameters: {timeout: 10000}},
        streaming: {
          rebufferingGoal: 2,
          bufferingGoal: 10,
          bufferBehind: 30,
          retryParameters: {
            timeout: 10000,
            maxAttempts: 10,
            backoffFactor: 1.1
          }
        }
      };

      this._player = new LiveStreaming.shaka.Player(this._videoElement);
      this.loadPlayer(playerOptions);

      return;
    }
  }

  getStats(): LiveStreamingStatistics {
    if (!this._player) {
      return {
        width: 0,
        height: 0,
        currentTime: 0,
        lag: 0,
        networkState: NetworkStates.NetworkNoSource
      };
    }

    const statistics = {
      ...this._player.getStats(),
      currentTime: 0,
      lag: 0
    };
    const currentTime = this._videoElement['currentTime'] || 0;
    const trueCurrentTime = (Date.now() - this._options.originStartTime) / 1000;
    const lag = Math.max(0.0, trueCurrentTime - currentTime);

    if (this._videoElement) {
      statistics.currentTime = currentTime;
      statistics.lag = lag;

      if (this._videoElement.buffered && this._videoElement.buffered.length) {
        statistics.dataBuffered = this._videoElement.buffered.end(0);
      }
    }

    if (statistics.estimatedBandwidth > 0) {
      statistics.networkState = NetworkStates.NetworkLoading;
    } else if (statistics.playTime > 0) {
      statistics.networkState = NetworkStates.NetworkIdle;
    } else if (statistics.video) {
      statistics.networkState = NetworkStates.NetworkEmpty;
    } else {
      statistics.networkState = NetworkStates.NetworkNoSource;
    }

    return statistics;
  }

  dispose(): void {
    if (this._player) {
      this._player.unload();
      this._player.destroy();
    }

    this._player = null;
  }

  private loadPlayer(playerConfig: ShakaOptions): void {
    this._player.configure(playerConfig);

    const ignored = this._player.load(this._manifestURI).then(() => {
      this._logger.info('[%s] DASH live stream has been loaded', this._streamId);

      if (this._videoElement.play) {
        this._videoElement.play();
      }
    }).catch(e => {
      this._logger.error('[%s] Error while loading DASH live stream [%s]', this._streamId, e.code, e);
    });
  }
}