/**
 * Copyright 2021 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */

export default class SdpParser {
  private readonly _sdp: string;
  private readonly _audioCodec: string;
  private readonly _videoCodec: string;

  constructor(sdp: string) {
    this._sdp = sdp;
    this._audioCodec = this.applyCodec('audio');
    this._videoCodec = this.applyCodec('video');
  }

  get audioCodec(): string {
    return this._audioCodec;
  }

  get videoCodec(): string {
    return this._videoCodec;
  }

  applyCodec(type: string): string {
    const splitSdp = this._sdp.split(/(\r\n|\r|\n)/);
    const usedCodecLine = splitSdp.find(item => item.includes(`a=rtpmap:${splitSdp.find(i => i.includes(`m=${type}`)).split(' ')[3]}`));
    const usedCodec = usedCodecLine.split(' ')[1];
    const codecName = usedCodec.split('/')[0];

    return codecName;
  }
}