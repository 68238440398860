/**
 * Copyright 2021 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import IPeerConnection from '../../rtc/IPeerConnection';
import SDK from '../SDK';
import {IStream} from './IStream';
import EndPoint, {SetRemoteDescriptionStatus} from '../discovery/EndPoint';
import PeerConnectionService, {IPeerConnectionOfferInit} from '../../rtc/PeerConnectionService';
import Discovery from '../discovery/Discovery';
import FeatureEnablement from '../../dom/FeatureEnablement';
import ChannelState from '../channels/ChannelState';
import assertUnreachable from '../../lang/assertUnreachable';
import RtcConnectionMonitor from '../../dom/RtcConnectionMonitor';
import Durations from '../../time/Duration';
import {ILogger} from '../../logger/LoggerInterface';
import LoggerFactory from '../../logger/LoggerFactory';
import ChannelContext from '../channels/ChannelContext';

const iceCandidateAccumulationInterval = 100;
const defaultStreamSetupTimeout = 30000;

export default class RealTimeStream implements IStream {
  private readonly _logger: ILogger = LoggerFactory.getLogger('RealTimeStream');
  private readonly _channelContext: ChannelContext;
  private readonly _handleStreamFailure: () => Promise<void>;

  constructor(channelContext, handleStreamFailure: () => Promise<void>) {
    this._channelContext = channelContext;
    this._handleStreamFailure = handleStreamFailure;
  }

  start(uri, token, listenOnStreamSetup, playMediaStreamInVideoElement): Promise<void> {
    return Promise.all<EndPoint, IPeerConnectionOfferInit>([
      Discovery.discoverClosestEndPointWithCaching(uri),
      PeerConnectionService.createPeerConnectionOffer()
    ])
      .then(([endPoint, {localOffer, peerConnection}]) => {
        this._channelContext.online.value = true;
        this._channelContext.endPoint.value = endPoint;
        this._logger.info('Connecting to [%s]', endPoint.toString());
        this._logger.info('Local offer:\n' + localOffer.sdp);

        if (FeatureEnablement.clientOfferDisabled || !peerConnection.supportsSetConfiguration || !peerConnection.supportsGetConfiguration) {
          peerConnection.close();
          peerConnection = null;
          localOffer = null;
        }

        this._channelContext.peerConnection.value = peerConnection;

        return endPoint.subscribe(token, localOffer, this._channelContext.failureCount.value);
      })
      .then(({status, stream, rtcConfiguration, setRemoteDescriptionResponse, createOfferDescriptionResponse, createAnswerDescriptionResponse, lag}) => {
        this._channelContext.stream.value = stream;
        this._channelContext.lag.value = lag;

        this._channelContext.applySessionAndStreamPropertiesToVideoElement();

        this._logger.debug(
          '[%s] Subscribe completed [%s] [%j] [%j] [%j] [%j]',
          this._channelContext.streamId,
          status,
          rtcConfiguration,
          setRemoteDescriptionResponse,
          createOfferDescriptionResponse,
          createAnswerDescriptionResponse
        );

        this._channelContext.state.value = this._channelContext.mapSubscribeStatusToChannelStatus(status);

        this._channelContext.applyStatus(status);

        if (status !== 'ok') {
          return;
        }

        return this.applyRtcConfiguration(this._channelContext.peerConnection.value, rtcConfiguration)
          .then(peerConnection => {
            let submitCandidatesTimeout;
            let cancelDiscovery = false;
            let discoveryCompleted = false;
            const candidates: RTCIceCandidate[] = [];

            peerConnection.onicecandidate = (e): void => {
              if (this._channelContext.stream.value !== stream) {
                return;
              }

              if (this._channelContext.peerConnection.value !== peerConnection) {
                return;
              }

              if (cancelDiscovery) {
                return;
              }

              if (!SDK.sendLocalCandidates.value) {
                return;
              }

              if (e.candidate && e.candidate.candidate) {
                candidates.push(e.candidate);
              } else {
                discoveryCompleted = true;
              }

              if (!submitCandidatesTimeout) {
                submitCandidatesTimeout = setTimeout(() => {
                  if (this._channelContext.stream.value !== stream) {
                    return;
                  }

                  if (cancelDiscovery) {
                    return;
                  }

                  const ignored = this._channelContext.endPoint.value.addIceCandidates(stream, candidates, discoveryCompleted)
                    .then(({status, options}) => {
                      if (status !== 'ok') {
                        this._logger.warn('[%s] Failed to add ICE candidates with reason [%s]', this._channelContext.streamId, status);

                        return;
                      }

                      if (options.includes('cancel')) {
                        cancelDiscovery = true;
                      }

                      this._logger.info('[%s] Added ICE candidates with reason [%s] and options [%s]', this._channelContext.streamId, status, options);
                    })
                    .catch(e => {
                      this._logger.error('[%s] Failed to add ICE candidates', this._channelContext.streamId, e);
                    });
                }, iceCandidateAccumulationInterval);
              }
            };

            peerConnection.oniceconnectionstatechange = (): void => {
              if (this._channelContext.stream.value !== stream) {
                return;
              }

              if (this._channelContext.peerConnection.value !== peerConnection) {
                return;
              }

              const retryCallback = (): void => {
                // If we stop normally the peer connection is unregistered first.
                // Thus anytime we see a closed peer connection that is still valid, it is an error.
                this._channelContext.state.value = ChannelState.Error;

                if (this._channelContext.videoElement.value) {
                  this._channelContext.videoElement.value.pause();
                  this._channelContext.videoElement.value.srcObject = null;
                }

                this._channelContext.playing.value = false;
                this._channelContext.loading.value = true;

                const ignored = this._handleStreamFailure()
                  .catch(e => {
                    this._logger.error(
                      '[%s] Failed handling stream failure after peer connection stopped with state [%s]',
                      this._channelContext.streamId,
                      peerConnection.iceConnectionState,
                      e
                    );
                  });
              };

              switch (peerConnection.iceConnectionState) {
                case 'checking':
                case 'completed':
                case 'connected':
                case 'new':
                  return;

                case 'disconnected':
                case 'failed':
                  if (navigator.onLine) {
                    this._logger.info('[%s] ICE connection state changed to [%s], trying to reconnect', this._channelContext.streamId, peerConnection.iceConnectionState);
                    this.reconnectPeerConnection(peerConnection, retryCallback);
                  }

                  return;
                case 'closed':
                  this._logger.info('[%s] ICE connection state changed to [%s], retrying to connect', this._channelContext.streamId, peerConnection.iceConnectionState);
                  retryCallback();

                  return;
                default:
                  assertUnreachable(peerConnection.iceConnectionState);
              }
            };

            const mediaStreamPromise = new Promise<MediaStream>((resolve, reject) => {
              if (!FeatureEnablement.onTrackDisabled) {
                const timeoutId = setTimeout(() => reject(new Error('Stream setup timed out')), defaultStreamSetupTimeout);

                peerConnection.ontrack = (e): void => {
                  clearTimeout(timeoutId);

                  resolve(e.streams[0]);
                };

                return;
              }

              const trackListener = (e): void => {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                clearTimeout(timeoutId);
                peerConnection.removeEventListener('track', trackListener);
                peerConnection.removeEventListener('addstream', trackListener);

                if (e.streams) {
                  resolve(e.streams[0]);
                } else {
                  resolve(e.stream);
                }
              };

              const timeoutId = setTimeout(() => {
                peerConnection.removeEventListener('track', trackListener);
                peerConnection.removeEventListener('addstream', trackListener);
                reject(new Error('Stream setup timed out'));
              }, defaultStreamSetupTimeout);

              peerConnection.addEventListener('track', trackListener);
              peerConnection.addEventListener('addstream', trackListener);

              return;
            });

            return new Promise(resolve => {
              resolve();
            }).then(() => {
              if (!setRemoteDescriptionResponse) {
                return;
              }

              this._logger.info('[%s] Set local SDP offer [%s]', this._channelContext.streamId, setRemoteDescriptionResponse.sessionDescription.sdp);

              return peerConnection.setLocalDescription(setRemoteDescriptionResponse.sessionDescription);
            }).then(() => {
              if (!createAnswerDescriptionResponse) {
                return;
              }

              this._logger.info('[%s] Set remote SDP answer [%s]', this._channelContext.streamId, createAnswerDescriptionResponse.sessionDescription.sdp);

              return peerConnection.setRemoteDescription(createAnswerDescriptionResponse.sessionDescription);
            }).then(() => {
              if (!createOfferDescriptionResponse) {
                return;
              }

              this._logger.info('[%s] Set remote SDP offer [%s]', this._channelContext.streamId, createOfferDescriptionResponse.sessionDescription.sdp);

              return peerConnection.setRemoteDescription(createOfferDescriptionResponse.sessionDescription)
                .then(() => {
                  return peerConnection.createAnswer({
                    offerToReceiveAudio: true,
                    offerToReceiveVideo: true
                  });
                }).then(answer => {
                  this._logger.info('[%s] Set local SDP answer [%j]', this._channelContext.streamId, answer);

                  return this._channelContext.endPoint.value.setRemoteDescription(stream, answer);
                }).then(({status, sessionDescription}) => {
                  this._channelContext.state.value = this.mapSetRemoteDescriptionStatusToChannelStatus(status);

                  if (status !== 'ok') {
                    this._channelContext.playing.value = false;
                    this._channelContext.standby.value = true;
                    this._channelContext.stopped.value = false;

                    return;
                  }

                  return peerConnection.setLocalDescription(sessionDescription);
                });
            }).then(() => {
              listenOnStreamSetup.success(this._channelContext.streamId);

              return mediaStreamPromise;
            }).then(mediaStream => {
              this._channelContext.mediaStream.value = mediaStream;

              const rtcConnectionMonitor = new RtcConnectionMonitor(peerConnection, mediaStream, this._channelContext.endPoint.value.roundTripTime / 4);

              this._channelContext.disposables.add(rtcConnectionMonitor);

              const ignored = rtcConnectionMonitor.rtcStatistic.subscribe(statistics => {
                this._channelContext.rtcStatistics.value = statistics;

                if (!this._channelContext.rtcVideoStatistic && !this._channelContext.rtcAudioStatistic) {
                  this._channelContext.rtcAudioStatistic = statistics.audio;
                  this._channelContext.rtcVideoStatistic = statistics.video;

                  return;
                }

                let audioTrackFailed = false;
                let videoTrackFailed = false;

                if (statistics.audio) {
                  if (this._channelContext.rtcAudioStatistic && this._channelContext.rtcAudioStatistic.timestamp !== statistics.audio.timestamp) {
                    audioTrackFailed = this._channelContext.rtcAudioStatistic && this._channelContext.rtcAudioStatistic.bytesReceived === statistics.audio.bytesReceived;

                    if (audioTrackFailed && navigator.onLine) {
                      this._logger.info(
                        '[%s] Audio track failed with last bytesReceived [%s] is equal to previous bytesReceived [%s] within [%s]',
                        this._channelContext.streamId,
                        statistics.audio.bytesReceived,
                        this._channelContext.rtcAudioStatistic.bytesReceived,
                        new Durations(statistics.audio.timestamp - this._channelContext.rtcAudioStatistic.timestamp).toIsoString()
                      );
                    }

                    this._channelContext.rtcAudioStatistic = statistics.audio;
                  }
                }

                if (statistics.video) {
                  if (this._channelContext.rtcVideoStatistic && this._channelContext.rtcVideoStatistic.timestamp !== statistics.video.timestamp) {
                    videoTrackFailed = this._channelContext.rtcVideoStatistic && this._channelContext.rtcVideoStatistic.bytesReceived === statistics.video.bytesReceived;

                    if (videoTrackFailed && navigator.onLine) {
                      this._logger.info(
                        '[%s] Video track failed with last bytesReceived [%s] is equal to previous bytesReceived [%s] within [%s]',
                        this._channelContext.streamId,
                        statistics.video.bytesReceived,
                        this._channelContext.rtcVideoStatistic.bytesReceived,
                        new Durations(statistics.video.timestamp - this._channelContext.rtcVideoStatistic.timestamp).toIsoString()
                      );
                    }

                    this._channelContext.rtcVideoStatistic = statistics.video;
                  }
                }

                if ((videoTrackFailed || audioTrackFailed) && navigator.onLine) {
                  const retryCallback = (): void => {
                    this._channelContext.state.value = ChannelState.Error;

                    if (this._channelContext.videoElement.value) {
                      this._channelContext.videoElement.value.pause();
                      this._channelContext.videoElement.value.srcObject = null;
                    }

                    this._channelContext.playing.value = false;
                    this._channelContext.loading.value = true;

                    rtcConnectionMonitor.dispose();

                    const ignored = this._handleStreamFailure()
                      .catch(e => {
                        this._logger.error(
                          '[%s] Failed handling stream failure after track stopped with state [%s]',
                          this._channelContext.streamId,
                          peerConnection.iceConnectionState,
                          e
                        );
                      });
                  };

                  this.reconnectPeerConnection(peerConnection, retryCallback);
                } else {
                  this._channelContext.peerConnectionReconnectAttempts = 0;
                }
              });

              if (!SDK.automaticallyPlayMediaStream) {
                this._channelContext.autoMuted.value = false;
                this._channelContext.autoPaused.value = true;
                this._channelContext.loading.value = false;
                this._channelContext.playing.value = false;
                this._channelContext.state.value = ChannelState.Paused;

                return;
              }

              return playMediaStreamInVideoElement(mediaStream);
            });
          });
      });
  }

  private async applyRtcConfiguration(
    optionalPeerConnection: IPeerConnection | null,
    rtcConfiguration: RTCConfiguration): Promise<IPeerConnection> {
    if (!optionalPeerConnection) {
      rtcConfiguration = this.truncateIceServers(rtcConfiguration);

      return SDK.peerConnectionFactory.value.createPeerConnection(rtcConfiguration);
    }

    const newRtcConfiguration = {
      ...optionalPeerConnection.getConfiguration(),
      ...rtcConfiguration
    };

    optionalPeerConnection.setConfiguration(newRtcConfiguration);

    return optionalPeerConnection;
  }

  private truncateIceServers(configuration: RTCConfiguration): RTCConfiguration {
    const iceServers: RTCIceServer[] = [];

    for (let i = 0; i < configuration.iceServers.length; i++) {
      const urls: string[] = [];

      for (let index = 0; index < 2; index++) {
        const url = configuration.iceServers[i].urls[index];

        if (url) {
          urls.push(configuration.iceServers[i].urls[index]);
        }
      }

      iceServers.push({
        urls: urls,
        username: configuration.iceServers[i].username,
        credential: configuration.iceServers[i].credential
      });
    }

    configuration.iceServers = iceServers;

    return configuration;
  }

  private reconnectPeerConnection(peerConnection: IPeerConnection, retryCallback: () => void): void {
    if (peerConnection.iceConnectionState === 'closed') {
      return;
    }

    this._channelContext.state.value = ChannelState.Reconnecting;

    if (this._channelContext.peerConnectionReconnectAttempts < SDK.maximalNumberOfPeerConnectionReconnectAttempts || !SDK.automaticallyReconnectPeerConnection) {
      this._channelContext.peerConnectionReconnectAttempts++;

      if (FeatureEnablement.clientOfferDisabled ||
        !peerConnection.supportsSetConfiguration ||
        !peerConnection.supportsGetConfiguration ||
        this._channelContext.failureCount.value
      ) {
        return;
      }

      this._logger.info('Reconnecting peer connection by restarting ICE');

      const localOffer = peerConnection.currentLocalDescription;
      const ignored = peerConnection.createOffer({iceRestart: true}).then(offer => {
        return peerConnection.setLocalDescription(offer).then(() => {
          return peerConnection.setLocalDescription(localOffer);
        });
      })
        .catch(e => {
          this._logger.error('Failed to reconnect peer connection', e);
          this._channelContext.peerConnectionReconnectAttempts = 0;
          retryCallback();
        });

      return;
    }

    this._logger.info('Failed to reconnect peer connection after [%s] attempts, performing full recovery', this._channelContext.peerConnectionReconnectAttempts);
    this._channelContext.peerConnectionReconnectAttempts = 0;
    retryCallback();
  }

  private mapSetRemoteDescriptionStatusToChannelStatus(status: SetRemoteDescriptionStatus): ChannelState {
    switch (status) {
      case 'ok':
        return ChannelState.Starting;
      case 'unauthorized':
        return ChannelState.Unauthorized;
      case 'not-found':
      case 'capacity':
      case 'rate-limited':
      case 'timeout':
        return ChannelState.Recovering;
      case 'failed':
        return ChannelState.Error;
      default:
        assertUnreachable(status);
    }
  }
}